import {
  createRouter,
  createWebHistory
} from "vue-router";
import api from "../api";
import store from "@/store";
// import {getCompany} from "@/api/company";

const routes = [{
    path: "/",
    name: "Home",
    component: () => import("../views/Home.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: () => import("../views/404.vue"),
  },
  // {
  //   path: "/card",
  //   name: "Card",
  //   component: () => import("../views/Cards.vue"),
  // },
  {
    path: "/trn",
    name: "Transactions",
    component: () => import("../views/Transactions.vue"),
  },
  {
    path: "/trn-n",
    name: "TransactionsNew",
    component: () => import("../views/TransactionsNew.vue"),
  },
  {
    path: "/balances",
    name: "Balances",
    component: () => import("../views/Balances.vue"),
  },
  {
    path: "/holders",
    name: "Holders",
    component: () => import("../views/Holders.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/profile",
    name: "Profile",
    component: () => import("../views/Profile.vue"),
  },
  {
    path: "/tools",
    name: "Tools",
    component: () => import("../views/Toolses"),
  },
  {
    path: "/statistic",
    name: "Statistic",
    component: () => import("../views/Statistic.vue"),
  },
  {
    path: "/report",
    name: "Report",
    component: () => import("../views/Report.vue"),
  },
  {
    path: "/payments",
    name: "Payments",
    component: () => import("../views/Payments.vue"),
  },
  {
    path: "/card",
    name: "CardsNew",
    component: () => import("../views/CardsNew.vue"),
  },
  {
    path: "/card/:id",
    name: "CardPage",
    component: () => import("../views/CardPage.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (from.name) {
    localStorage.removeItem('cards-tab')
  }
  // getCompany(this);
  // if (store.state.company.companyList) {
  //     store.state.company.companyList.forEach((el) => {
  //         if (el.id === store.state.company.curCompany.id) {
  //             store.commit("setCurCompany", el);
  //         }
  //     });
  // }
  if (to.name === "Login") {
    next();
  } else {
    if (localStorage.getItem("__token") == null) {
      next("Login");
    } else {
      api
        .post("token/verify", {
          token: localStorage.getItem("__token")
        })
        .then((r) => {
          next();
        })
        .catch((err) => {
          if (err.request.status == "403" || err.request.status == "401") {
            next("Login");
            //next()
          } else {
            next();
          }
        });
    }
  }
});

router.afterEach(() => {});

export default router;