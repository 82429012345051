import axios from "axios";

const api = axios.create({
  baseURL: window.apiUrl,
  timeout: 100000,
  headers: {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("__token"),
  },
});
 
// Если в режиме разработки, локальный адрес
// if (typeof webpackHotUpdate != "undefined") {
// 	api.defaults.baseURL = 'http://localhost:8000/api/'
// }

// api.interceptors.request.use(request => {
// 	console.log('Отправлен запрос ' + request.url, request)
// 	return request
// })

// api.interceptors.response.use(response => {
// 	console.log('Принят ответ ' + response.config.url, response)
// 	return response
// })

export default api;
