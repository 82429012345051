<template v-cloak>
  <div class="min-h-screen flex relative lg:static">
    <div class="min-h-screen flex relative flex-auto min-w-full">
      <keep-alive>
        <VueSidebarMenuAkahon :isSearch="false" />
      </keep-alive>

      <div class="flex-auto flex flex-column max-w-full overflow-hidden">
        <app-header class="min-w-full" style="z-index: 10002"></app-header>
        <div
          v-if="  $store.state.company.companyList && 
        $store.state.company.companyList.length &&
        $store.state.company.curCompany"
          class="pb-0 flex flex-column flex-auto px-0 sm:px-2"
        >
          <slot> </slot>
        </div>
        <Skeleton v-else width="100%" height="100%" />
      </div>
    </div>
  </div>
  <div
    class="lock align-items-center justify-content-center top-0 left-0 right-0 bottom-0 bg-black-alpha-90 fixed"
    style="z-index: 10010"
  >
    <h2 class="text-white">
      Переверните устройство для корректной работы приложения
    </h2>
  </div>
  <!-- <v-tour
    name="myTour"
    :steps="steps"
    :options="myOptions"
    :callbacks="myCallbacks"
  ></v-tour> -->
</template>

<script>
import AppHeader from "@/components/layouts/AppHeader.vue";
import VueSidebarMenuAkahon from "@/components/layouts/Sidebar-menu-akahon";
import FilterCompany from "../FilterCompany.vue";

import { getVersion } from "../../api/version";

import store from "@/store";

export default {
  name: "Base",
  components: {
    AppHeader,
    VueSidebarMenuAkahon,
    FilterCompany,
  },
  data() {
    return {
      currentComp: [],
      version: null,
      // myOptions: {
      //   useKeyboardNavigation: true,
      //   labels: {
      //     buttonSkip: "Пропустить",
      //     buttonPrevious: "Назад",
      //     buttonNext: "Дальше",
      //     buttonStop: "Понятно",
      //   },
      // },
      // myCallbacks: {
      //   onStop: this.myCustomStopCallback,
      // },
      // steps: [
      //   {
      //     target: '[data-v-step="0"]',
      //     content: "Вкладка для работы со всеми картами",
      //     params: {
      //       highlight: true,
      //     },
      //     params: {
      //       enableScrolling: false,
      //     },
      //   },
      //   {
      //     target: '[data-v-step="1"]',
      //     content: "Чтобы перейти в список карт данной компании",
      //     params: {
      //       highlight: true,
      //     },
      //     params: {
      //       enableScrolling: false,
      //     },
      //   },
      // ],
    };
  },
  methods: {
    // myCustomStopCallback() {
    //   localStorage.setItem("tourByCard", "true");
    // },
  },
  mounted() {
    let href = document.location.host;
    if (document.location.hash === "#dev") {
      console.log(document.location.hash);
      localStorage.setItem("isDev", false);
    }
    let isDev = localStorage.getItem("isDev");
    if ((href === "localhost:8080" || href === "tanos.dt-teh.ru") && !isDev) {
      this.$toast.add({
        severity: "info",
        summary: `Это тестовый ресурс для разработчиков.`,
        detail: `Стабильная версия на официальном сайте.`,
      });
    }
    // console.log(this.$tours);
    // setTimeout(() => {
    //   let rout = this.$route.name;
    //   let load = localStorage.getItem("tourByCard");
    //   if (!load && rout == "Home") {
    //     this.$tours["myTour"].start();
    //   } else {
    //     this.$tours["myTour"].nextStep();
    //   }
    // }, 300);
    // localStorage.removeItem('token')
  },
  created() {
    getVersion();
    // setInterval(() => {
    //   getVersion();
    // }, 1800000);
  },
  computed: {
    companyList() {
      return store.state.company.companyList !== null;
    },
    curCompany() {
      return store.state.company.curСompany !== null;
    },
  },
};
</script>

<style lang="scss">
// .wrapper-hidden {
//   @media (min-width: 768px) {
//     overflow: hidden;
//   }
// }
.lock {
  display: none !important;
}
@media (orientation: landscape) and (max-height: 400px) {
  /* При горизонтальной ориентации фоновым цветом сайта будет белый */
  .lock {
    display: flex !important;
  }
  body {
    overflow: hidden !important;
  }
}
</style>