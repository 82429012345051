<template>
  <div
    class="shadow-3 flex relative lg:static justify-content-between bg-white w-full"
    style="min-height: 42px"
  >
    <div class="w-full py-2 mx-4 fillter">
      <FilterCompany class="w-full" />
    </div>

    <a
      class="cursor-pointer profile-btn align-items-center px-3 text-white lg:hidden p-ripple"
      v-styleclass="{
        selector: '@next',
        enterClass: 'hidden',
        leaveToClass: 'hidden',
        hideOnOutsideClick: true,
      }"
    >
      <i class="pi pi-bars text-2xl"></i>
    </a>

    <a
      v-ripple
      class="profile-btn cursor-pointer align-items-center px-3 lg:hidden text-700 p-ripple"
      v-styleclass="{
        selector: '@next',
        enterClass: 'hidden',
        leaveToClass: 'hidden',
        hideOnOutsideClick: true,
      }"
    >
      <i class="pi pi-ellipsis-v text-xl"></i>
    </a>
    <div
      class="hidden lg:flex lg:justify-content-end absolute lg:static w-full lg:w-min lg:ml-2 ml-0 left-0 top-100 z-3 shadow-2 lg:shadow-none bg-white"
    >
      <ul class="flex list-none p-0 m-0 flex-column lg:flex-row">
        <li class="h-full relative">
          <a
            v-ripple
            class="cursor-pointer h-full inline-flex align-items-center text-bluegray-800 py-3 lg:py-0 px-3 border-left-2 lg:border-left-none lg:border-bottom-2 border-transparent hover:border-500 transition-colors transition-duration-150 p-ripple"
            v-styleclass="{
              selector: '@next',
              enterClass: 'hidden',
              enterActiveClass: 'scalein',
              leaveToClass: 'hidden',
              leaveActiveClass: 'fadeout',
              hideOnOutsideClick: true,
            }"
          >
            <!-- <span class="pi pi-user mr-2"></span> -->
            <!--            <div style="width: 33px; height: 33px"-->
            <!--                 class="-->
            <!--                mr-2-->
            <!--                p-2-->
            <!--                text-sm-->
            <!--                bg-bluegray-800-->
            <!--                text-gray-300-->
            <!--                border-circle-->
            <!--                text-center-->
            <!--                 flex-->
            <!--          align-items-center-->
            <!--          justify-content-center-->
            <!--              "-->
            <!--            >{{-->
            <!--                $store.state.curUser-->
            <!--                    ? createName($store.state.curUser.user_name)-->
            <!--                    : "AA"-->
            <!--              }}-->
            <!--            </div-->
            <!--            >-->
            <Avatar
              class="ava bg-black-alpha-90 text-primary p-badge-sm p-overlay-badge"
              shape="circle"
            >
              <div v-if="$store.state.curUser">
                {{
                  $store.state.curUser
                    ? createName($store.state.curUser.user_name)
                    : "AA"
                }}
              </div>
              <Skeleton v-else width="100%" height="100%" shape="circle"></Skeleton>
            </Avatar>
            <div>
              <span
                v-if="$store.state.curUser"
                class="mx-2 font-medium text-bluegray-800"
                >{{
                  $store.state.curUser
                    ? $store.state.curUser.phone
                    : "79999999999"
                }}</span
              >
              <Skeleton v-else width="78px" height="20px" class="mx-2" ></Skeleton>

            </div>
            <i class="pi pi-angle-down"></i>
          </a>
          <div
            class="hidden static lg:absolute w-full bg-white left-0 top-100 shadow-none lg:shadow-2 origin-top border-round pl-3 lg:pl-0 bg-white"
          >
            <ul class="list-none p-0 m-0">
              <li>
                <router-link
                  to="/profile"
                  v-ripple
                  class="cursor-pointer h-full inline-flex align-items-center text-bluegray-800 border-left-2 border-transparent hover:border-500 transition-colors transition-duration-150 p-3 p-ripple"
                >
                  <span class="pi pi-user mr-2"></span>
                  <span class="font-medium">Профиль </span>
                </router-link>
              </li>
              <li>
                <router-link
                  v-ripple
                  to="/tools"
                  class="cursor-pointer h-full inline-flex align-items-center text-bluegray-800 border-left-2 border-transparent hover:border-500 transition-colors transition-duration-150 p-3 p-ripple"
                >
                  <span class="pi pi-cog mr-2"></span>
                  <span class="font-medium">Настройки</span>
                </router-link>
              </li>
              <li>
                <a
                  @click="logout"
                  v-ripple
                  class="cursor-pointer h-full inline-flex align-items-center text-bluegray-800 border-left-2 border-transparent hover:border-500 transition-colors transition-duration-150 p-3 p-ripple"
                >
                  <i class="pi pi-sign-out mr-2"></i>
                  <span class="font-medium">Выход</span>
                </a>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { getProfile } from "@/api/profile";
import FilterCompany from "../FilterCompany.vue";
import store from "@/store";
import { createProfileName } from "@/handlers/createProfileName";

export default {
  data() {
    return {
      name: "Header",
      companies: [],
      profiles: null,
      selectedCompany: null,
      components: {
        FilterCompany,
      },
    };
  },
  mounted() {
    // console.log(store.state.company.curCompany);
  },
  beforeCreate() {
    getProfile(this);
  },
  methods: {
    logout() {
      localStorage.removeItem("token");
      store.commit("setCurCompany", null);
      store.commit("setCompanyList", []);
      setTimeout(() => {
        this.$router.push("/login");
      }, 50);
    },
    toggle(event) {
      this.$refs.op.toggle(event);
    },
    createName(value) {
      return createProfileName(value);
    },
  },
  created() {},
  components: { FilterCompany },
};
</script>

<style lang="scss">
a {
  text-decoration: none;
}

.hover\:border-500:hover {
  border-color: transparent !important;
}

a[aria-current="page"] {
  &:hover {
    border: none !important;
  }

  border: none !important;
}
.profile-btn {
  display: inline-flex;
  @media (max-width: 768px) {
    display: none;
  }
}
.fillter {
  & .p-component {
    font-size: 0.8rem !important;
    @media (max-width: 1440px) {
      font-size: 1rem !important;
    }
  }

  .p-inputtext {
    padding: 0.6rem 0.5rem;
  }
}
.ava {
  span {
    margin-top: -8px;
  }
}
</style>
