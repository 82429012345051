<template>
  <div
    class="mobile-sidebar__header bg-bluegray-800 align-items-center justify-content-between"
    style="width: 100%; height: 60px"
  >
    <Button
      @click="display = !display"
      class="p-button p-button-rounded p-button-text bg-primary"
      v-tooltip="'Ваш личный менеджер'"
      icon="pi pi-phone"
    ></Button>
    <div class="mobile-sidebar__header-wrapper">
      <div class="ml-0 z-3">
        <ul class="flex list-none p-0 m-0 flex-column lg:flex-row">
          <li class="h-full relative right">
            <a
              v-ripple
              class="cursor-pointer h-full inline-flex align-items-center text-bluegray-800 py-3 lg:py-0 px-3 border-left-2 lg:border-left-none lg:border-bottom-2 border-transparent transition-colors transition-duration-150"
              v-styleclass="{
                selector: '@next',
                enterClass: 'hidden',
                enterActiveClass: 'scalein',
                leaveToClass: 'hidden',
                leaveActiveClass: 'fadeout',
                hideOnOutsideClick: true,
              }"
            >
              <Avatar
                class="bg-white text-bluegray-800"
                size="large"
                shape="circle"
              >
                {{
                  $store.state.curUser
                    ? createName($store.state.curUser.user_name)
                    : "AA"
                }}
              </Avatar>
            </a>
            <div
              class="hidden absolute bg-white shadow-none lg:shadow-2 origin-top border-round pl-3 lg:pl-0 bg-white"
              style="right: 0px; width: 300%"
            >
              <ul class="list-none p-0 m-0">
                <li>
                  <router-link
                    to="/profile"
                    v-ripple
                    class="cursor-pointer h-full inline-flex align-items-center text-bluegray-800 border-left-2 border-transparent hover:border-500 transition-colors transition-duration-150 p-3 p-ripple"
                  >
                    <span class="pi pi-user mr-2"></span>
                    <span class="font-medium">Профиль</span>
                  </router-link>
                </li>
                <li>
                  <a
                    v-ripple
                    class="cursor-pointer h-full inline-flex align-items-center text-bluegray-800 border-left-2 border-transparent hover:border-500 transition-colors transition-duration-150 p-3 p-ripple"
                  >
                    <span class="pi pi-cog mr-2"></span>
                    <span class="font-medium">Настройки</span>
                  </a>
                </li>
                <li>
                  <a
                    @click="logout"
                    v-ripple
                    class="cursor-pointer h-full inline-flex align-items-center text-bluegray-800 border-left-2 border-transparent hover:border-500 transition-colors transition-duration-150 p-3 p-ripple"
                  >
                    <i class="pi pi-sign-out mr-2"></i>
                    <span class="font-medium">Выход</span>
                  </a>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div
    class="mobile-sidebar__footer bg-bluegray-800 block md:hidden"
    style="width: 100%; height: 60px"
  >
    <ul class="mobile-sidebar__nav-list grid bg-bluegray-800 py-1">
      <li class="col-2" v-for="menuItem in menuItemsMobile" :key="menuItem">
        <router-link
          :to="menuItem.link"
          class="flex flex-column align-items-center"
          @click="curMoreMenu = false"
          :data-v-step="menuItem.name == 'Карты' && !isMobile ? 0 : null"
        >
          <i
            v-tooltip.right="menuItem.name"
            class="pi mb-2"
            :class="menuItem.icon || ''"
            >{{ menuItem.val ? menuItem.val : "" }}</i
          >
          <span class="links_name">{{ menuItem.name }}</span>
        </router-link>
      </li>
      <li class="col-2 relative">
        <a
          @click="showSubMenu = !showSubMenu"
          class="flex flex-column align-items-center"
        >
          <i class="pi pi-ellipsis-v mb-2"></i>
          <span class="links_name">Ещё</span>
        </a>
        <ul
          class="mobile-sidebar__nav-list bg-bluegray-800 m-0 sub-menu justify-content-center border-round-top-xl"
          :class="{ open: showSubMenu }"
        >
          <li
            class="col-2"
            v-for="menuItem in subMenuItemsMobile"
            :key="menuItem"
          >
            <router-link
              :to="menuItem.link"
              class="flex flex-column align-items-center"
              @click="curMoreMenu = true"
            >
              <i
                v-tooltip.right="menuItem.name"
                class="pi mb-2"
                :class="menuItem.icon || ''"
                >{{ menuItem.val ? menuItem.val : "" }}</i
              >
              <span class="links_name">{{ menuItem.name }}</span>
            </router-link>
          </li>
        </ul>
      </li>
    </ul>
  </div>

  <div
    class="sidebar bg-bluegray-800 pt-2 shadow-4"
    :class="isOpened ? 'open' : ''"
    :style="cssVars"
  >
    <div class="logo-details bg-bluegray-800">
      <div v-if="isOpened || this.isMobile()" class="logo__sm"></div>
      <span :class="{ 'circle-btn': isOpened }">
        <i
          class="pi hover:text-primary"
          :class="isOpened ? 'pi-chevron-left' : 'pi-align-justify'"
          id="btn"
          @click="opens"
        />
      </span>
    </div>
    <div
      v-if="isDev"
      class="text-center text-white text-3xl"
      :class="{ 'text-6xl': isOpened }"
    >
      <b>DEV</b>
    </div>

    <div class="sidebar_inner bg-bluegray-800 hidden md:block" style="">
      <div id="my-scroll">
        <ul class="nav-list">
          <li v-if="isSearch" @click="isOpened = !isOpened">
            <i class="pi pi-search" />
            <input
              type="text"
              :placeholder="searchPlaceholder"
              @input="$emit('search-input-emit', $event.target.value)"
            />
            <span class="tooltip">{{ searchTooltip }}</span>
          </li>

          <span v-for="(menuItem, index) in menuItems" :key="index">
            <li>
              <router-link
                class="bg-bluegray-800"
                :to="menuItem.link"
                :data-v-step="menuItem.name == 'Карты' && isMobile ? 0 : null"
              >
                <i
                  v-tooltip.right="menuItem.name"
                  class="pi"
                  :class="menuItem.icon || ''"
                  >{{ menuItem.val ? menuItem.val : "" }}</i
                >
                <span class="links_name">{{ menuItem.name }}</span>
              </router-link>
            </li>
          </span>
        </ul>
      </div>
    </div>
    <div
      class="mb-4 text-center"
      v-if="$store.state.company.curCompany !== null"
    >
      <Button
        @click="display = !display"
        class="p-button p-button-rounded p-button-text bg-primary"
        v-tooltip="'Ваш личный менеджер'"
        icon="pi pi-phone"
      ></Button>
      <Dialog
        header="Ваш личный менеджер:
          
        "
        :position="setPosition"
        draggable="false"
        :class="!this.isMobile() ? 'w-3' : 'w-screen'"
        style="transition: all 0.15s linear 0.01s"
        :style="setMargin"
        v-model:visible="display"
      >
        <h4
          v-if="$store.state.company.curCompany.manager !== null"
          class="mb-2"
        >
          Личные контакты:
        </h4>
        <ul
          v-if="$store.state.company.curCompany.manager !== null"
          style="list-style: none; pointer-events: all"
        >
          <li class="mb-2">
            {{ $store.state.company.curCompany.manager.user_name }}
          </li>
          <li class="mb-2">
            <a :href="'tel:' + $store.state.company.curCompany.manager.phone">{{
              $store.state.company.curCompany.manager.phone
            }}</a>
          </li>
          <li class="mb-4">
            <a
              :href="'mailto:' + $store.state.company.curCompany.manager.email"
              >{{ $store.state.company.curCompany.manager.email }}</a
            >
          </li>
        </ul>
        <div v-else>Данные отсутсвуют</div>
        <!-- <h4 class="mb-2">Общие контакты:</h4>
        <ul style="list-style: none; pointer-events: all">
          <li class="mb-2"><a href="tel:+74957481408">8 (495) 748-14-08</a></li>
          <li class="mb-2">
            <a href="tel:+88007771497">8 (800) 777-14-97</a>
          </li>
          <li><a href="mailto:info@expcard.ru">info@expcard.ru</a></li>
        </ul> -->
      </Dialog>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import { createProfileName } from "@/handlers/createProfileName";

export default {
  name: "SidebarMenuAkahon",
  props: {
    //! Menu settings
    isMenuOpen: {
      type: Boolean,
      default: true,
    },
    menuTitle: {
      type: String,
      default: "Title",
    },
    menuLogo: {
      type: String,
      default: "",
    },
    menuIcon: {
      type: String,
      default: " ",
    },
    isPaddingLeft: {
      type: Boolean,
      default: true,
    },
    menuOpenedPaddingLeftBody: {
      type: String,
      default: "180px !important",
    },
    menuClosedPaddingLeftBody: {
      type: String,
      default: "60px !important",
    },
    //! Search
    isSearch: {
      type: Boolean,
      default: true,
    },
    searchPlaceholder: {
      type: String,
      default: "Search...",
    },
    searchTooltip: {
      type: String,
      default: "Search",
    },

    //! Profile detailes

    profileName: {
      type: String,
      default: "Fayzullo Saidakbarov",
    },
    profileRole: {
      type: String,
      default: "Frontend vue developer",
    },
    isExitButton: {
      type: Boolean,
      default: true,
    },

    //! Styles
    bgColor: {
      type: String,
      default: "#11101d",
    },
    secondaryColor: {
      type: String,
      default: "#1d1b31",
    },
    homeSectionColor: {
      type: String,
      default: "#e4e9f7",
    },
    logoTitleColor: {
      type: String,
      default: "#fff",
    },
    iconsColor: {
      type: String,
      default: "#fff",
    },
    itemsTooltipColor: {
      type: String,
      default: "#e4e9f7",
    },
    searchInputTextColor: {
      type: String,
      default: "#fff",
    },
    menuItemsHoverColor: {
      type: String,
      default: "#fff",
    },
    menuItemsTextColor: {
      type: String,
      default: "#fff",
    },
    menuFooterTextColor: {
      type: String,
      default: "#fff",
    },
  },
  data() {
    return {
      showSubMenu: false,
      curMoreMenu: false,
      display: false,
      width: 0,
      isOpened: JSON.parse(localStorage.getItem("isOpen")),
      // isMenuOpen: this.isOpened,
      closedPadding: this.menuClosedPaddingLeftBody,
      openedPadding: this.menuOpenedPaddingLeftBody,
      menuItems: [
        { link: "/", name: "Компании", icon: "pi-briefcase" },

        {
          link: "/balances",
          name: "Балансы",
          icon: "pi-money-bill",
        },
        {
          link: "/trn-n",
          name: "Транзакции",
          icon: "pi-chart-bar",
        },
        {
          link: "/card",
          name: "Карты",
          icon: "pi-credit-card",
        },
        {
          link: "/report",
          name: "Отчеты",
          icon: "pi-file",
        },
        {
          link: "/payments",
          name: "Платежи",
          icon: "pi-wallet",
        },
      ],
      menuItemsMobile: [
        { link: "/", name: "Компании", icon: "pi-briefcase" },
        {
          link: "/balances",
          name: "Балансы",
          icon: "pi-money-bill",
        },
        {
          link: "/card",
          name: "Карты",
          icon: "pi-credit-card",
        },
        {
          link: "/trn",
          name: "Транзакции",
          icon: "pi-chart-bar",
        },
      ],
      subMenuItemsMobile: [
        {
          link: "/payments",
          name: "Платежи",
          icon: "pi-wallet",
        },
        {
          link: "/report",
          name: "Отчеты",
          icon: "pi-file",
        },
      ],
    };
  },
  mounted() {
    window.document.body.style.paddingLeft = this.closedPadding;

    // window.matchMedia("(max-width: 768px)").matches
    //   ? (window.document.body.style.paddingLeft = "0px")
    //   : (window.document.body.style.paddingLeft = this.closedPadding);
    // window.matchMedia("(max-width: 768px)").matches
    //   ? (window.document.body.style.paddingTop = "60px !important")
    //   : (window.document.body.style.paddingTop = "0px");
    // window.matchMedia("(max-width: 768px)").matches
    //   ? (this.isOpened = false)
    //   : (this.isOpened = false);
  },
  methods: {
    toggle(event) {
      this.$refs.op.toggle(event);
    },
    updateWidth() {
      this.width = window.innerWidth;
    },
    logout() {
      // localStorage.removeItem("token");
      store.commit("setCurCompany", null);
      store.commit("setCompanyList", []);
      setTimeout(() => {
        this.$router.push("/login");
      }, 50);
    },
    toggle(event) {
      this.$refs.op.toggle(event);
    },
    createName(value) {
      return createProfileName(value);
    },
    isMobile() {
      return window.matchMedia("(max-width: 768px)").matches ? true : false;
    },
    opens() {
      this.isOpened = !this.isOpened;
      localStorage.setItem("isOpen", this.isOpened);
    },
  },
  computed: {
    cssVars() {
      return {
        // '--padding-left-body': this.isOpened ? this.menuOpenedPaddingLeftBody : this.menuClosedPaddingLeftBody,
        "--bg-color": this.bgColor,
        "--secondary-color": this.secondaryColor,
        "--home-section-color": this.homeSectionColor,
        "--logo-title-color": this.logoTitleColor,
        "--icons-color": this.iconsColor,
        "--items-tooltip-color": this.itemsTooltipColor,
        "--serach-input-text-color": this.searchInputTextColor,
        "--menu-items-hover-color": this.menuItemsHoverColor,
        "--menu-items-text-color": this.menuItemsTextColor,
        "--menu-footer-text-color": this.menuFooterTextColor,
      };
    },
    isDev() {
      return (
        document.location.host === "localhost:8080" ||
        document.location.host === "tanos.dt-teh.ru"
      );
    },
    setPosition() {
      if (!this.isMobile()) {
        return "bottomleft";
      } else {
        return "top";
      }
    },

    setMargin() {
      if (!this.isMobile()) {
        if (this.isOpened) {
          return "margin-left: 170px; margin-bottom: 0px";
        } else {
          return "margin-left: 60px; margin-bottom: 0px";
        }
      } else {
        return "margin-top: 100px;";
      }
    },
  },
  watch: {
    isOpened(value) {
      if (!window.matchMedia("(max-width: 768px)").matches) {
        window.document.body.style.paddingLeft =
          this.isOpened && this.isPaddingLeft
            ? this.openedPadding
            : this.closedPadding;
      } else {
        this.closedPadding = "0px";
        this.openedPadding = "0px";
      }
    },
    width(value) {
      if (this.display) {
        this.display = false;
        this.display = true;
      }
      this.isMobile();
    },
  },
  created() {
    window.addEventListener("resize", this.updateWidth);
    if (localStorage.getItem("isOpen") === null) {
      localStorage.setItem("isOpen", false);
    }
  },
};
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.mobile-sidebar {
  position: fixed;
  min-width: 100vw;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  z-index: 10002;
  top: 0;
  display: none;
  flex-direction: column;
  justify-content: space-between;
  pointer-events: none;
  padding-bottom: calc(60px + env(safe-area-inset-bottom));

  @media (max-width: 768px) {
    display: flex;
  }

  &__header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10004;
    display: none;
    background-image: var(--logo-xl-url);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 50px;
    padding: 0 0 0 1rem;

    @media (max-width: 768px) {
      display: flex;
    }
  }
  &__footer {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10004;
    padding-bottom: calc(60px + constant(safe-area-inset-bottom));
    padding-bottom: calc(60px + env(safe-area-inset-bottom));
    display: none;
    @media (max-width: 768px) {
      display: block;
    }
    a,
    i {
      color: white;
      font-size: 1.15rem !important;
      &.curLink {
        color: var(--baseLight);
      }
    }

    .links_name {
      font-size: 0.8rem;
    }
  }
  &__nav-list {
    padding: 1rem;
    margin: 0;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    li {
      width: 64px;
    }
    &.sub-menu {
      display: block;
      position: absolute;
      top: 0;
      transform: translateX(50%);
      margin: 0 auto;
      right: 50%;
      z-index: -1;
      padding: 0.2rem !important;
      opacity: 0;
      transition: all 0.2s ease-in-out 0.1s;
      li {
        width: 65px;
        margin-bottom: 0.5rem;
      }
      &.open {
        top: -120px;
        opacity: 1;
      }
    }
  }

  &__header-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
  }
}

.menu-logo {
  width: 30px;
  margin: 0 10px 0 10px;
}

.sidebar {
  // position: fixed !important;
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  // position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  // min-height: 100vh;
  /* overflow-y: auto; */
  min-width: 60px !important;
  width: 60px;
  background: var(--bg-color);
  /* padding: 6px 14px 0 14px; */
  z-index: 10003;
  // transition: all 0.5s ease .3s;
  transition: all 0.15s linear 0.01s;
  backface-visibility: hidden;
  // transition: none !important;
  @media (max-width: 768px) {
    top: 160px !important;
    width: 100% !important;
    height: 60px !important;
    padding: 0 !important;
    overflow-y: hidden !important;
    top: -5px !important;
    z-index: 2;
    display: none;
  }
  //   &::before {
  //     content: "";
  //     position: absolute;
  //     top: 0;
  //     right: 0;
  //     left: 180px;
  //     bottom: 0;
  //     width: calc(100vw - 180px);
  //     height: 0;
  //     background: rgba($color: #000000, $alpha: 0);
  //     backdrop-filter: blur(0px);
  //     z-index: -1;
  //   }
}

.sidebar.open {
  min-width: 170px !important;

  // &::before {
  //   height: 100vh !important;
  //   background: rgba($color: #000000, $alpha: 0.1);
  //   backdrop-filter: blur(8px);
  // }
  @media (max-width: 768px) {
    width: 100% !important;
    height: 100vh !important;
  }
}

.sidebar {
  .sidebar_inner {
    display: flex !important;
    flex-direction: column !important;
    justify-content: space-between !important;
    flex-grow: 1 !important;
    max-height: calc(100% - 60px) !important;
    overflow-y: hidden !important;
    @media (max-width: 768px) {
      display: none !important;
      max-height: unset !important;
    }
  }
  &.open {
    .sidebar_inner {
      @media (max-width: 768px) {
        display: flex !important;
      }
    }
  }
}

.sidebar {
  .logo-details {
    // height: 35px !important;
    height: 60px;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    // justify-content: space-between !important;
    position: relative !important;
    padding: 6px 6px;
    @media (max-width: 768px) {
      margin: 0 !important;
      height: 60px !important;
      justify-content: end !important;
    }
  }
  &.open {
    .logo-details {
      justify-content: space-between !important;
    }
  }
}

.sidebar .logo-details .icon {
  opacity: 0;
  transition: all 0.15s linear 0.01s;
}

.sidebar .logo-details .logo_name {
  color: var(--logo-title-color);
  font-size: 20px;
  font-weight: 600;
  opacity: 0;
  transition: all 0.15s linear 0.01s;
}

.sidebar.open .logo-details .icon,
.sidebar.open .logo-details .logo_name {
  opacity: 1;
}

.sidebar {
  .logo__sm {
    @media (min-width: 768px) {
      display: none !important;
    }
  }
  &.open {
    .logo__sm {
      @media (min-width: 768px) {
        display: block !important;
      }
    }
  }
}

.sidebar .logo-details #btn {
  min-width: 30px;
  // height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.15s linear 0.01s;
  font-size: 18px;
  cursor: pointer;
  /* transition: all 0.5s ease; */
  @media (max-width: 768px) {
    bottom: 50% !important;
    height: 25px !important;
  }
}

.sidebar.open .logo-details #btn {
  text-align: right !important;
  @media (max-width: 768px) {
    text-align: unset !important;
  }
}

.sidebar i {
  color: var(--icons-color);
  // height: 60px;
  min-width: 50px;
  font-size: 28px;
  text-align: center;
  line-height: 60px;
}

.sidebar .nav-list {
  // margin-top: 20px;
  /* margin-bottom: 60px; */
  /* height: 100%; */
  /* min-height: min-content; */

  // @media (max-width: 768px) {
  //   display: flex;
  // }
  @media (max-width: 768px) {
    height: 100% !important;
    overflow-y: hidden !important;
  }
}

.sidebar li {
  position: relative;
  margin: 8px 0;
  list-style: none;
}

// .sidebar li .tooltip {
//   position: absolute;
//   top: -20px;
//   left: calc(100% + 15px);
//   z-index: 3;
//   background: var(--items-tooltip-color);
//   box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
//   padding: 6px 12px;
//   border-radius: 4px;
//   font-size: 15px;
//   font-weight: 400;
//   opacity: 0;
//   white-space: nowrap;
//   pointer-events: none;
//   transition: 0s;
// }

// .sidebar li:hover .tooltip {
//   opacity: 1;
//   pointer-events: auto;
//   transition: all 0.4s ease;
//   top: 50%;
//   transform: translateY(-50%);
// }

/* .sidebar.open li .tooltip {
  display: none;
} */

.sidebar input {
  font-size: 15px;
  color: var(--serach-input-text-color);
  font-weight: 400;
  outline: none;
  height: 50px;
  width: 100%;
  width: 50px;
  border: none;
  border-radius: 12px;
  transition: all 0.5s ease;
  background: var(--secondary-color);
}

.sidebar.open input {
  padding: 0 20px 0 50px;
  width: 100%;
}

.sidebar .pi-search {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  font-size: 22px;
  background: var(--secondary-color);
  color: var(--icons-color);
}

.sidebar.open .pi-search:hover {
  background: var(--secondary-color);
  color: var(--icons-color);
}

.sidebar .pi-search:hover {
  background: var(--menu-items-hover-color);
  color: var(--bg-color);
}

.sidebar li a {
  display: flex;
  height: 100%;
  width: 100%;
  border-radius: 12px;
  align-items: center;
  text-decoration: none;
  transition: all 0.15s linear 0.01s;
  background: var(--bg-color);
}

.sidebar li a:hover {
  background: var(--menu-items-hover-color);
}

a[aria-current="page"] i,
a[aria-current="page"] span {
  color: var(--primary-color) !important;
}

.sidebar li a .links_name {
  color: var(--menu-items-text-color);
  font-size: 15px;
  font-weight: 400;
  white-space: nowrap;
  opacity: 0;
  display: none;
  pointer-events: none;
  transition: 0.4s;
}

.sidebar.open li a .links_name {
  display: inline;
  opacity: 1;
  pointer-events: auto;
}

.sidebar li a:hover .links_name,
.sidebar li a:hover i {
  transition: all 0.15s linear 0.01s;
  color: var(--base) !important;
}

.sidebar {
  li i {
    height: 50px;
    line-height: 50px;
    font-size: 18px;
    border-radius: 12px;
    margin-left: 5px;
  }
  &.open {
    li i {
      min-width: 50px !important;
    }
  }
}

.sidebar div.profile {
  position: relative;
  height: 60px;
  width: 78px;
  /* left: 0;
  bottom: 0; */
  padding: 10px 14px;
  background: var(--secondary-color);
  /*transition: all 0.5s ease;*/
  overflow-y: hidden;
}

.sidebar.open div.profile {
  width: 250px;
}

.sidebar div .profile-details {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.sidebar div img {
  height: 45px;
  width: 45px;
  object-fit: cover;
  border-radius: 6px;
  margin-right: 10px;
}

.sidebar div.profile .name,
.sidebar div.profile .job {
  font-size: 15px;
  font-weight: 400;
  color: var(--menu-footer-text-color);
  white-space: nowrap;
}

.sidebar div.profile .job {
  font-size: 12px;
}

.sidebar .profile #log_out {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  background: var(--secondary-color);
  width: 100%;
  height: 60px;
  line-height: 60px;
  border-radius: 0px;
  font-size: 18px;
  /*transition: all 0.5s ease;*/
}

.sidebar.open .profile #log_out {
  width: 50px;
  background: var(--secondary-color);
  opacity: 1;
}

.sidebar.open .profile:hover #log_out {
  opacity: 1;
}

.sidebar.open .profile #log_out:hover {
  opacity: 1;
  color: var(--base);
}

.sidebar .profile #log_out:hover {
  color: var(--base);
}

.home-section {
  position: relative;
  background: var(--home-section-color);
  min-height: 100vh;
  top: 0;
  left: 78px;
  width: calc(100% - 78px);
  transition: all 0.5s ease;
  z-index: 2;
}

.sidebar.open ~ .home-section {
  left: 250px;
  width: calc(100% - 250px);
}

.home-section .text {
  display: inline-block;
  color: var(--bg-color);
  font-size: 25px;
  font-weight: 500;
  margin: 18px;
}

.my-scroll-active {
  overflow-y: auto;
}

#my-scroll {
  overflow-y: auto;
  // height: calc(100% - 60px);
  height: 100% !important;
  overflow-y: hidden !important;
}

#my-scroll::-webkit-scrollbar {
  display: none;
  /* background-color: rgba(255, 255, 255, 0.2);
  width: 10px;
  border-radius:5px  */
}

/* #my-scroll::-webkit-scrollbar-thumb{
  background-color: red;
  border-radius:5px
}
#my-scroll::-webkit-scrollbar-button:vertical:start:decrement{
  display:none;
}
#my-scroll::-webkit-scrollbar-button:vertical:end:increment{
  display:none;
} */
@media (max-width: 420px) {
  .sidebar li .tooltip {
    display: none;
  }
}
.p-dialog-header {
  pointer-events: all !important;
}
.circle-btn {
  background: var(--base);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  transform: translateX(20px);
  box-shadow: 0px 5px 10px rgba($color: #000000, $alpha: 0.3);
  &:hover {
    background: var(--primaryTextColor) !important;
    i {
      color: var(--base) !important;
    }
  }
  i {
    width: 100% !important;
    height: 100% !important;
  }
}
</style>
